/* General Styles */
.cart-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .cart-items {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .item-image img {
    width: 100px; /* Adjust size as needed */
    height: auto;
    border-radius: 4px;
  }
  
  .item-details {
    flex: 1;
    padding-left: 10px;
  }
  
  .item-name {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 5px;
  }
  
  .item-price {
    font-size: 0.9em;
    color: #888;
  }
  
  .item-quantity {
    font-size: 0.9em;
    color: #888;
  }
  
  .item-actions {
    margin-left: auto;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .delete-button img {
    width: 20px; /* Adjust size as needed */
    height: 20px;
  }
  
  .cart-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
  
  .total {
    font-size: 1.2em;
  }
  
  .checkout-button {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #2980b9;
  }
  
  @media (max-width: 768px) {
    .cart-container {
      padding: 10px;
    }
  
    .cart-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .item-image {
      margin-bottom: 10px;
    }
  
    .item-details {
      padding-left: 0;
      text-align: left;
    }
  
    .item-actions {
      margin-top: 10px;
    }
  }
  