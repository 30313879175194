.footer {
    height: auto;
    /* margin-top: -10%; */
    background-color: #1A1A1A;
    color: white;
    padding: 10px 20px;
}

.footerheading {
    font-size: 18px;
    font-weight: 700;
    color: orange;
}

.fotertext {
    font-size: 16px;
    font-weight: 500;
}

.fotertextlink {
    font-size: 16px;
    font-weight: 500;
    /* line-height: 1px; */
}

.footermain {
    padding: 60px 20px;
    display: flex;
    justify-content: space-between;
    gap: 60px;
}

.first {
    width: 25%;
}

.second {
    width: 20%;
}

 .third{
    width: 30%;
} 
.four {
    width: 25%;
}

.iconset {
    display: flex;
    gap: 10px;
    font-size: 40px;
    cursor: pointer;
}

.fotertextlink a {
    text-decoration: none;
    color: white;
}

.linksdiv{
    display: flex;
    flex-direction: row;
    gap: 40px !important;
    /* justify-content:; */
    text-align: justify;
}
/* .floating {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
   }
   
   .fab-icon {
    margin-top: 16px;
   } */

@media(max-width:768px) {
    .footermain {
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .first {
        width: 100%;
    }

    .second {
        width: 100%;
    }

    .third {
        width: 100%;
    }

    .four {
        width: 100%;
    }
}