/* General Styles */
.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .about-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .about-header h1 {
    font-size: 2.5em;
    color: #2c3e50;
  }
  
  .about-header p {
    font-size: 1.2em;
    color: #7f8c8d;
  }
  
  .about-content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .about-section {
    margin-bottom: 30px;
  }
  
  .about-section h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #2980b9;
  }
  
  .about-section p,
  .about-section ul {
    font-size: 1em;
    line-height: 1.6;
    color: #4a4a4a;
  }
  
  .about-section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .about-section ul li {
    margin-bottom: 10px;
  }
  
  .about-section a {
    color: #3498db;
    text-decoration: none;
  }
  
  .about-section a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .about-header h1 {
      font-size: 2em;
    }
  
    .about-header p {
      font-size: 1em;
    }
  
    .about-section h2 {
      font-size: 1.5em;
    }
  }
  
  @media (max-width: 480px) {
    .about-header h1 {
      font-size: 1.8em;
    }
  
    .about-header p {
      font-size: 0.9em;
    }
  
    .about-section h2 {
      font-size: 1.2em;
    }
  }
  