/* General Styles */
.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-header h1 {
    font-size: 2.5em;
    color: #2c3e50;
  }
  
  .contact-header p {
    font-size: 1.2em;
    color: #7f8c8d;
  }
  
  .contact-header a {
    color: #3498db;
    text-decoration: none;
  }
  
  .contact-header a:hover {
    text-decoration: underline;
  }
  
  .contact-content {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #2980b9;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact-header h1 {
      font-size: 2em;
    }
  
    .contact-header p {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .contact-header h1 {
      font-size: 1.8em;
    }
  
    .contact-header p {
      font-size: 0.9em;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 0.9em;
    }
  
    .submit-button {
      font-size: 1em;
    }
  }
  