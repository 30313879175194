/* FailPayment.css */

.fail-payment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Full viewport height */
    background-color: #f7f9fc; /* Light background color */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .fail-payment-content {
    text-align: center;
    background-color: #ffffff; /* White background for content */
    padding: 30px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 500px; /* Limit content width */
    width: 100%;
  }
  
  .fail-payment-logo {
    width: 120px; /* Logo size */
    margin-bottom: 20px;
  }
  
  .fail-payment-title {
    color: #e74c3c; /* Red color for error */
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .fail-payment-message {
    color: #555;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .fail-payment-button {
    display: inline-block;
    padding: 12px 20px;
    background-color: #1a1a1a; /* Primary button color */
    color: #ffffff; /* Button text color */
    text-decoration: none;
    border-radius: 4px; /* Rounded corners */
    font-size: 16px;
    transition: background-color 0.3s ease; /* Smooth color transition */
  }
  
  .fail-payment-button:hover {
    background-color: #333; /* Darker shade on hover */
  }
  
  @media (max-width: 768px) {
    .fail-payment-content {
      padding: 20px;
    }
  
    .fail-payment-title {
      font-size: 20px;
    }
  
    .fail-payment-message {
      font-size: 14px;
    }
  
    .fail-payment-button {
      font-size: 14px;
    }
  }
  