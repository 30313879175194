.forgetpassword {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20vh; /* Adjust as needed */
    background-color: #f7f9fc; /* Light background color */
}

.forgetpassword form {
    background-color: #ffffff; /* White background for the form */
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    max-width: 400px; /* Limit form width */
    width: 100%;
    box-sizing: border-box;
}

.forgetpassword label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.forgetpassword input[type="text"] {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px; /* Space between input and button */
    border: 1px solid #ddd; /* Border color */
    border-radius: 4px; /* Rounded corners for input */
    font-size: 16px;
    box-sizing: border-box;
}

.forgetpassword button {
    width: 100%;
    padding: 12px;
    background-color: #1A1A1A; /* Primary button color */
    color: #fff; /* Button text color */
    border: none;
    border-radius: 4px; /* Rounded corners for button */
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth color transition */
}

.forgetpassword button:hover {
    background-color: #333; /* Darker shade on hover */
}

/* Responsive design */
@media (max-width: 768px) {
    .forgetpassword {
        padding: 20px;
    }
    
    .forgetpassword form {
        padding: 15px;
    }
    
    .forgetpassword label {
        font-size: 14px;
    }
    
    .forgetpassword input[type="text"] {
        font-size: 14px;
        padding: 10px;
    }
    
    .forgetpassword button {
        font-size: 14px;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .forgetpassword {
        padding: 15px;
    }
    
    .forgetpassword form {
        padding: 10px;
    }
    
    .forgetpassword label {
        font-size: 12px;
    }
    
    .forgetpassword input[type="text"] {
        font-size: 12px;
        padding: 8px;
    }
    
    .forgetpassword button {
        font-size: 12px;
        padding: 8px;
    }
}
