/* .limtedtimesection{
    background-image: url(../../Images/allsocks.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
} */
.limted {
    display: flex;
    gap: 10px;
    padding: 20px;
}

.limtedtimefirst {
    width: 100%;
    position: relative;
}

.limtedtext {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    line-height: 25px;
    z-index: 999;
}

.limtedtextfirst {
    color: white;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.limtedtextsecond {
    color: white;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.limtedtextthird {
    color: white;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.shopallbutton {
    width: 150px;
    border: 0px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px;
    letter-spacing: 1.5px;
    background: none;
    border: 2px solid white;
    color: white;
}

.limtedtimesecond {
    width: 100%;
    position: relative;
}

.limtedtext1 {
    position: absolute;
    /* bottom: 0; */
    top: 290px;
    left: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    flex-direction: column;
    line-height: 25px;
    z-index: 999;
    background-color: white;
}

.limtedtextfirst1 {
    color: black;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.limtedtextsecond1 {
    color: black;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.limtedtextthird1 {
    color: black;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.shopallbutton1 {
    width: 150px;
    border: 0px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px;
    letter-spacing: 1.5px;
    background: none;
    border: 2px solid black;
    color: black;
}

.limtedtimefirst img {
    width: 100%;
    height: 500px;
}

.limtedtimesecond img {
    width: 100%;
    height: 500px;
}

@media(max-width:768px) {
    .limted {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
    }

    .limtedtext1 {
        left: 8%;
    }
    .limtedtimefirst img {
        width: 100%;
        height: 250px;
    }
    
    .limtedtimesecond img {
        width: 100%;
        height: 250px;
    }
    .limtedtext1 {
        position: absolute;
        /* bottom: 0; */
        top: 24px;
        left: 9%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 200px;
        flex-direction: column;
        line-height: 25px;
        z-index: 999;
        background-color: white;
    }
}